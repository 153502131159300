<template>
    <ValidationProvider
        class="checkbox-wrap mb-2 "        
        :rules="rules"
        :name="$attrs.name"        
        v-slot="{ valid, errors }"
        tag="div"
        :vid="name" 
    >
        <div class="d-flex align-items-center">
            <input 
                class="form-check-input"
                :class="{'form-alert--border': errors[0]}" 
                type="checkbox" 
                :id="name"
                :value="value"
                :true-value="trueValue"
                :false-value="falseValue"
                :checked="checked == 1 ? true : false"
                :state="errors[0] ? false : (valid ? true : null)"
                @change="$emit('input', getValue($event.target.checked));"
            >                        
            <label 
                v-if="label"
                class="ms-2 mt-2"
                :class="small ? 'label-small' : ''" 
                :for="name"
            >
                {{ label }}                
                <span v-if="rules" class="label-red"> *</span>
            </label>
            <label 
                v-if="!label" 
                :for="name"
                class="d-flex ms-2"
                :class="small ? 'label-small' : ''" 
            >
                <slot v-if="!label"></slot>
            </label>
        </div>
        <!-- error heandling -->            
        <div class="input-error"  v-if=" errors[0] ">{{ errors[0] }}</div>      
    </ValidationProvider>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
export default {
    name: 'CustomInput',
    components: {
            ValidationProvider
    },
    props: {
        label: {
            required: false,
            type: String
        },
        name: {
            required: false,
            type: String,
            default: null
        },
        checked: {
            required: false,
            type: null,
            default: null
        },
        rules: {
            required: false,
            type: [Object, String],
            default: ""
        },
        value: {
            required: false,
            type: null,
            default: ""
        },
        small: {
            required: false,
            type: Boolean,
            default: false
        },
        trueValue:{
            required:false,
            type:[Boolean, String, Object, Array]
        },
        falseValue:{
            required:false,
            type:[Boolean, String, Object, Array]
        }
    },
    methods:{
        getValue(val){
            if (val ){
                if (this.trueValue){
                    return this.trueValue
                } else {
                    return 1
                }
            } else {
                 if (this.falseValue){
                    return this.falseValue
                } else {
                    return 0
                }
            }
        }
    }
}
</script>