<template>
    <div id="jatekszabaly" class="rules position-relative">
        <div class="container-xl">

            <div class="row px-3 px-md-0">
                <h2 class="text-uppercase display-2 text-white text-center mb-5 mt-0 ">
                    Játék lépései
                </h2>
                <div class="col-12 col-md-10 offset-md-1 rules-container">
                    <div class="row">
                        <div class="col-12">
                            <h3 class="text-primary text-center pb-md-3 mb-0 text-uppercase">Vásárolj!</h3>
                        </div>
                        <div class="col-12 col-md-4 text-center">
                            <img src="/imgs/rules-01.svg" class="img-fluid mb-md-0" alt="Vásárolj"/>
                        </div>
                        <div class="col-12 col-md-8">
                            <div class="d-flex justify-content-center rules__text flex-md-row flex-column align-items-center align-items-md-start">
                                <span class="rules__number px-2 text-primary me-3 mb-md-0 mb-3">01</span>
                                <p class="text-center text-md-start">Vásárolj egyidejűleg <span class="fw-800">legalább 2500 Ft értékben</span> a promócióban részt vevő bármely <span class="text-primary text-decoration-underline"><a v-scroll-to="{ el: '#termekek', offset: -80 }" class='fw-800 text-primary'>Zewa, Libresse, Libero vagy Tena Lady terméket</a></span> az <span class="fw-800">Auchan áruházakban</span>, vagy az <span class="fw-800">Auchan Online Áruházban </span><a href="https://online.auchan.hu" target="_blank">(a <span class="text-primary text-decoration-underline fw-800">https://online.auchan.hu</span> oldalon keresztül).</a></p>
                            </div>
                            <div class="d-flex rules__text flex-md-row flex-column align-items-center align-items-md-start">
                                <span class="rules__number px-2 text-primary me-3 mb-md-0 mb-3">02</span>
                                <p class="text-center text-md-start">A nyereményjáték időtartama:<br/><span class="fw-800">2022. szeptember 29 - október 26.</span></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-10 offset-md-1 rules-container my-5">
                    <div class="row">
                        <div class="col-12">
                            <h3 class="text-primary text-center pb-md-3 mb-0 text-uppercase">Regisztrálj!</h3>
                        </div>
                        <div class="col-12 col-md-4 text-center">
                            <img src="/imgs/rules-02.svg" class="img-fluid mb-md-0" alt="Regisztrálj"/>
                        </div>
                        <div class="col-12 col-md-8">
                            <div class="d-flex justify-content-center rules__text flex-md-row flex-column align-items-center align-items-md-start">
                                <span class="rules__number px-2 text-primary me-3 mb-md-0 mb-3">03</span>
                                <p class="text-center text-md-start">Regisztrálj, majd töltsd fel a vásárlást igazoló bizonylat adatait és játssz a nyereményekért!</p>
                            </div>
                            <div class="d-flex rules__text flex-md-row flex-column align-items-center align-items-md-start">
                                <span class="rules__number px-2 text-primary me-3 mb-md-0 mb-3">04</span>
                                <p class="text-center text-md-start"><span class="fw-800">A vásárlást igazoló eredeti bizonylat</span> sértetlen, ép állapotban történő megőrzése <span class="fw-800">2022. november 26-ig</span> szükséges.</p>
                            </div>
                            <div class="d-flex rules__text flex-md-row flex-column align-items-center align-items-md-start">
                                <span class="rules__number px-2 text-primary me-3 mb-md-0 mb-3">05</span>
                                <p class="text-center text-md-start">Ne feledd, minél <span class="fw-800">többször vásárolsz</span> a promócióban részt vevő termékekből egyidejűleg legalább 2500 Ft értékben, annyiszor <span class="fw-800">több esélyed van a nyerésre!</span></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-10 offset-md-1 rules-container">
                    <div class="row">
                        <div class="col-12">
                            <h3 class="text-primary text-center pb-md-3 mb-0 text-uppercase">Nyerj!</h3>
                        </div>
                        <div class="col-12 col-md-4 text-center">
                            <img src="/imgs/rules-03.svg" class="img-fluid mb-md-0" alt="Nyerj"/>
                        </div>
                        <div class="col-12 col-md-8">
                            <div class="d-flex justify-content-center rules__text flex-md-row flex-column align-items-center align-items-md-start">
                                <span class="rules__number px-2 text-primary me-3 mb-md-0 mb-3">06</span>
                                <p class="text-center text-md-start">A játék ideje alatt előre meghatározott <span class="fw-800">nyerőidőpontokban</span> (08:00-22:00 között) megnyerhető <span class="fw-800">naponta 1 db 5000 Ft</span> értékű <span class="fw-800">Zewa ajándékcsomag,</span> és <span class="fw-800">hetente 1 db 20 000 Ft</span> értékű <span class="fw-800">Auchan ajándékkártya.</span></p>
                            </div>
                            <div class="d-flex rules__text flex-md-row flex-column align-items-center align-items-md-start">
                                <span class="rules__number px-2 text-primary me-3 mb-md-0 mb-3">07</span>
                                <p class="text-center text-md-start">A <span class="fw-800">fődíj 1 db Citywell Cruise elektromos robogó</span>. A játék lezárását követően a <span class="fw-800">fődíj sorsolása: 2022. november 02. 14 óra.</span></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 text-center">
                    <img src="/imgs/good-luck.svg" class="img-fluid py-5" alt="Sok sikert"/>
                </div>
                <div class="col-12 flex-wrap flex-md-nowrap justify-content-center d-none d-md-flex">                                                      
                    <button
                        class="btn btn-secondary mb-4 mb-md-0 me-0 me-md-4"
                        v-scroll-to="{el:!$store.state.user ? '#regisztracio' : '#palyazat',  offset: -80}"
                        @click="setGtm('Játszani szeretnék')"
                    >
                        Játszom!
                    </button>
                    <a class="btn btn-outline-primary " :href="`/pdf/Jatekszabalyzat.pdf?` + TimeStamp" target="_blank" @click="setGtm('Játékszabályzat')">
                        Játékszabályzat
                    </a> 
                </div>  
            </div> <!-- end of ROW -->
        </div>        
    </div>
</template>

<script>
export default {
    data () {
        return {
         
        }
    },
    methods:{
        setGtm(btnName){
            this.GTtrackGA4({
                'event' : 'event',
                'category': 'jatek_lepesei',
                'action': 'gomb_kattintas',
                'label': btnName,                
                'clicked_text':btnName,
                'action_type': 'gomb_kattintas'
            })
        }
    }
}
</script>