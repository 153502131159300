<template>
    <div id="nyertesek" class="winners pb-0">
        <div class="container-xl">
            <h2 class="font-header text-primary text-uppercase text-center mb-5 mt-0 mt-md-5">
                Nyertesek
            </h2>
            
            <div class="row pb-5">                
                <div class="col-12 col-xl-8 offset-xl-2 mb-0 mb-md-5 text-center text-dark mb-5">
                    <p class="m-0 winners__text">
                        Kedves Játékosunk!
                        <br/>A napi nyeremények* nyertesei az adott napot követő első munkanapon (pénteki/szombati/vasárnapi nyeremények esetében a következő hétfőn), míg a heti nyeremények* nyertesei csütörtökönként (2022. október 06., 2022. október 13., 2022. október 20., 2022 október 27.) kerülnek kiértesítésre a Lebonyolító által küldött e-mail üzenetben.
                        <br/><br/>*A nyerőidőpontok 8 és 22 óra között kerülnek kisorsolásra.
                    </p>
                </div>
                <div class="col-12 col-xl-8 offset-xl-2 mb-0 mb-md-5 text-center text-dark mb-5">
                    <div class="winners__event p-4">
                        <h4 class="text-primary text-uppercase">Sorsolás</h4>
                        <p class="winners__text">A fődíj sorsolására 2022. november 02-án 14:00 órakor kerül sor. A sorsolás helyszíne: A4C Marketing Kft. székhelye (1092 Budapest, Bakáts tér 2., bejárat a Bakáts utca 5-7. felől)</p>
                    </div> 
                </div> 
            </div>
            
            <!-- NYertesek -->
            <div class="row">
                <!-- DROPDOWN BTN -->
                <div class="col-12">
                    <div class="d-md-flex justify-content-around align-items-top">
                        <div 
                            v-for="win in wins"
                            :key="'winners' + win.id"
                            class="winners-collapse d-flex flex-column justify-content-start align-items-start mb-5 mb-xl-0"
                            data-bs-toggle="collapse" 
                            :data-bs-target="'#winner-' + win.id" 
                            aria-expanded="false" 
                            aria-controls="collapseExample"
                        >
                            <div class="win text-center">
                                <img :src="`/imgs/${win.img}`"  class="img-fluid win--main" :alt="win.name" :style="win.styleCss" />
                                <div class="d-flex justify-content-between align-items-center mb-2 mx-2">
                                    <div class="text-dark display-6 font-secondary pt-2 winners__title text-start fw-600" v-html="win.line2"></div>
                                    <!--<div class="winners-collapse-button">
                                        <span></span>                              
                                    </div>-->
                                </div>
                                <div class="winners__border py-3 mx-2">
                                    <p class="font-secondary m-0 text-start">{{win.text}}</p>
                                </div>
                            </div>                           
                            
                            <!-- DROPDOWN LIST -->
                            <!--<div 
                                class="collapse w-100 mx-2" 
                                :id="'winner-' + win.id"                       
                            >
                                <div  v-if="win.winners.length>0" class="winners-collapse-content me-3">
                                    <h4 class="text-uppercase text-primary pt-2">Nyertesek</h4>
                                    <p 
                                        v-for="(winner, idx) in win.winners"
                                        :key="'name' + idx"
                                        class="mb-1 text-left font-secondary"
                                    >
                                        {{winner.name}} - {{winner.winning_time}}
                                    </p>
                                </div>
                                <div v-else class="winners-collapse-content me-3">
                                    <h4 class="text-uppercase text-primary pt-2">Nyertesek</h4>
                                    <p class="text-dark font-secondary">Hamarosan..</p>

                                </div>
                            </div>   -->                 
                        </div>
                    </div>
                </div>
            </div> 

            <div class="row">
                <div class="col-12 my-4 my-md-5">
                    <div class="d-flex flex-column">
                        <p class="text-center font-secondary">A kisorsolt nyertesek részére a nyeremények átadása megtörtént!</p>
                        <p class="text-center font-secondary my-5 py-md-5">A képek csak illusztrációk.</p>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>


<script>
export default {
    data () {
        return {
            winnersAll:[],
            wins: [        
                {
                    id: 3,
                    name: 'napi',
                    type:'napi',
                    line1: 'NAPI',
                    line2: '5000 Ft értékű Zewa<br/>ajándékcsomag',                    
                    winners: [],
                    img:'napi-winners.png',
                    text:'A Zewa prémium gondoskodást nyújt kiemelkedően puha termékeivel, melyek nem csak a bőrrel, de a környezettel is kíméletesek, emellett praktikus háztartási segítséget nyújtanak a legkülönbözőbb helyzetekben.'                  
                },                                      
                {
                    id: 2,
                    name: 'heti',
                    type:'heti',
                    line1: 'HETI',
                    line2: '20 000 Ft értékű Auchan<br/>ajándékkártya',                    
                    winners: [],
                    img:'heti-winners.png',
                    text:'Akár ünnepi összejövetelre készülsz vagy csak egy hétvégi nagybevásárlásra, mindig jól jön az Auchan ajándékkártya, ami most 20 000 Ft értékben járulhat hozzá tudatos választásaidhoz!'                  
                },
                {
                    id: 1,
                    name: 'fődíj',
                    type:'fodij',
                    line1: 'FŐDÍJ',
                    line2: 'Citiwell Cruise<br/>elektromos robogó',                    
                    winners: [],
                    img:"fodij-winners.png",
                    text: 'Robogj velünk egy fenntarthatóbb jövőért! Tapasztald meg a városi motorozás új dimenzióját és közlekedj harmóniában a környezettel a stílusos, kényelmes és halk Citywell Cruise elektromos robogóval, amely tökéletesen beleillik a tudatos életmódba.'                
                },  
            ]
        }
    },
    methods: {
        // imgUrl(nr){
        //     return '/imgs/win-' + nr + '.png'
        // },
        selectWinners(){
            let _this = this
            this.wins.forEach( item => {                
                let winTypeWinners = _this.winnersAll.filter( element => element.type == item.type )
                // console.log('winTypeWinners',winTypeWinners)
                if (winTypeWinners)
                    item.winners = winTypeWinners
            })
        }
    },
    created(){
        this.get('info/winners',{})
        .then((resp) => {
          this.winnersAll = resp.data.winners
          this.selectWinners()
        }).catch((err) => {
            console.log(err)
        })
    }
    
}
</script>