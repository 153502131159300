<template>
    <div id="termekek" class="products py-5 position-relative">
        <div class="container-xl">                    
            <div class="row">                
                <div class="col-md-10 offset-md-1 d-flex flex-column text-center">
                    <h2 class="font-header text-white text-uppercase text-center mb-5 mt-0 ">
                        Részt vevő termékek
                    </h2>
                    <div class="products-container__inner">
                        <p class="text-white font-secondary">Vásárolj egyidejűleg <span class="fw-800">legalább 2500 Ft</span> értékben a promócióban részt vevő bármely Zewa, Libresse, Libero vagy Tena Lady terméket az <span class="fw-800">Auchan áruházakban</span>, vagy az <span class="fw-800">Auchan Online Áruházban</span> (a <a href="https://online.auchan.hu" target="_blank"><span class="fw-800 text-decoration-underline">https://online.auchan.hu</span></a> oldalon keresztül). A teljes listát <a href="/pdf/resztvevo_termekek.pdf" target="_blank"><span class="fw-800 text-decoration-underline">itt találod</span></a>. A választék áruházanként eltérő lehet.</p>
                        <p class="text-white font-secondary fw-600 mt-5">Tudj meg többet az Essity és márkáinak fenntarthatósági törekvéseiről!</p>
                        <div class="text-center my-md-5 my-4">
                            <a class="btn btn-secondary mx-auto" href="/pdf/fenntarthatosagi.pdf" target="_blank">
                                essity és a fenntarthatóság
                            </a>  
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-8 offset-md-2">
                    <div class="products-container form-inner-container d-flex justify-content-around align-items-center">
                         <img src="/imgs/zewa.jpg" class="brand-img img-fluid" alt="Zewa"/>
                        <img src="/imgs/libresse.jpg" class="brand-img img-fluid" alt="Libresse"/>
                        <img src="/imgs/libero.jpg" class="brand-img img-fluid" alt="Libero"/>
                        <img src="/imgs/tena.jpg" class="brand-img img-fluid" alt="Tena"/>
                    </div>
                    <p class="text-white font-secondary text-center my-4">A Játékban kizárólag a listában szereplő termékek vesznek részt, minden más ettől eltérő termék vásárlása érvénytelen a játék szempontjából.</p>
                    <div class="text-center mt-4">
                        <a class="btn btn-secondary mx-auto" href="/pdf/resztvevo_termekek.pdf" target="_blank" @click="setGtm('Részt vevő termékek listája')">
                            részt vevő termékek listája
                        </a>
                    </div>
                </div>      
            </div>         
        </div>
    </div>
</template>

<script>
export default {
    methods: {   
        setGtm(btnName){
            this.GTtrackGA4({
                'event' : 'event',
                'category': 'jatekban_reszt_vevo_termekek',
                'action': 'gomb_kattintas',
                'label': btnName,                
                'clicked_text':btnName,
                'action_type': 'gomb_kattintas'
            })
        }
    }
}
</script>