<template>
  <div>
      <Header />          
      <Hero />          
      <Rules />
    
      <!-- logged out -->
      <div v-if="!this.$store.state.user">
        <LogIn v-if="loginShow" />        
        <Register />                        
      </div>

      <!-- logged in -->
      <div 
        v-else 
        class="send-form-bg"
      >
        
          <SendForm v-if="$store.state.promoStatus != 'after' " @compSend="isNewCompSended = true" @resetCompSend="isNewCompSended = false"/>
          <MySendings :getMyNewSendings="isNewCompSended" />
          <MyData />            
        
      </div>                             
      <Winners /> 
    <Products />
    <Contact />
    <Footer />  
  </div>
  
</template>

<script>
// import ForgottenEmail from "@/components/ForgottenEmail.vue"
import Header from "@/components/Header.vue"
import Hero from "@/components/Hero.vue"
import Rules from "@/components/Rules.vue"
import LogIn from "@/components/LogIn.vue"
import Register from "@/components/Register.vue"
import SendForm from "@/components/SendForm.vue"
import MySendings from "@/components/MySendings.vue"
import MyData from "@/components/MyData.vue"
import Winners from "@/components/Winners.vue"
import Products from "@/components/Products.vue"
import Contact from "@/components/Contact.vue"
import Footer from "@/components/Footer.vue"

export default {
  components: {
    // ForgottenEmail,
    Header,
    Hero,    
    Rules,
    LogIn,
    Register,
    SendForm,
    MySendings,
    MyData,
    Winners,
    Products,
    Contact,
    Footer
  },  
  data(){
    return{
      isNewCompSended : false
    }
  }  
}
</script>