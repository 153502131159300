<template>
    <div>
        <div class="hero__outer">
            <div class="hero" id="hero">        
                <div class="hero-container">
                    <!-- CONTENT -->
                    <div class="container-xl">
                        <div class="row pt-5">
                            <div class="col-12 col-md-7">
                                <div class="row">
                                    <div class="col-12 text-md-start text-center text-primary">
                                        <div class="hero-top pt-lg-5">
                                            <h1>
                                                Együtt a fenntarthatóbb jövőért!
                                            </h1>                                                                
                                        </div>
                                    </div>    
                                    <div class="col-12">
                                        <div class="hero__lead text-md-start text-center text-dark mt-3">
                                            <p class="fw-600"><span class="display-3 fw-600">Vásárolj</span> bármilyen promócióban részt vevő Essity (Zewa, Libresse, Libero vagy Tena Lady) terméket legalább 2500 Ft értékben és <span class="display-3 fw-600">nyerj!</span></p>
                                        </div>   
                                    </div>
                                    <div class="col-12">
                                        <div class="text-md-start text-center text-dark mt-3">
                                            <p class="hero__time-text fw-600">Nyereményjáték időtartama:</p>
                                            <p class="text-primary hero__date-wrap">2022. szeptember 29 – október 26. </p>
                                            <div>
                                                <button
                                                    class="btn btn-primary mb-3 order-md-2 me-md-2 md-btn"
                                                    v-scroll-to="{el:!$store.state.user ? '#regisztracio' : '#palyazat',  offset: -80}"
                                                    @click="setGtm('Játszani szeretnék')"
                                                >
                                                    Játszom!
                                                </button>
                                                <a class="btn btn-secondary me-0 ms-lg-2 order-md-1 mb-3 md-btn" :href="`/pdf/Jatekszabalyzat.pdf?` + TimeStamp" target="_blank" @click="setGtm('Játékszabályzat')">
                                                    Játékszabályzat
                                                </a>   
                                            </div>  
                                        </div>   
                                    </div>
                                </div>
                            </div>
                            <!-- NYEREMÉNYEK -->
                            <div class="col-12 col-md-5">
                                <div class="row hero__winnings">
                                    <div class="col-12 col-md-6 position-relative">
                                        <img src="/imgs/nyeremeny-napi.png" class="daily mb-5 mb-md-0 position-absolute d-md-block d-none" alt="Naponta"/>
                                        <img src="/imgs/nyeremeny-fodij-mobile.png" width="320" class="mx-auto img-fluid mb-5 mb-md-0 d-block d-md-none" alt="Fődíj"/>
                                    </div>
                                    <div class="col-12 col-md-6 position-relative">
                                        <img src="/imgs/nyeremeny-hetente.png" class="weekly mb-5 mb-md-0 position-absolute d-md-block d-none" alt="Hetente"/>
                                        <img src="/imgs/nyeremeny-hetente-mobile.png" width="320" class="mx-auto img-fluid mb-5 mb-md-0 d-block d-md-none" alt="Hetente"/>
                                    </div>
                                    <div class="col-12 position-relative">
                                        <img src="/imgs/nyeremeny-fodij.png" class="full-price mb-5 mb-md-0 position-absolute d-md-block d-none" alt="Fődíj"/>
                                        <img src="/imgs/nyeremeny-napi-mobile.png" width="320" class="mx-auto img-fluid mb-5 mb-md-0 d-block d-md-none" alt="Naponta"/>
                                    </div>                                                        
                                </div>
                            </div>
                        </div>
                        <!-- apróbetűs rész -->  
                        <div class="row hero__footer mt-md-5 pt-md-5">
                            <div class="col-12 pb-md-2 mt-3 mb-md-4 text-center">
                                <p>A vásárlást igazoló eredeti bizonylatot sértetlen, ép állapotban 2022. november 26-ig meg kell őrizni. <br/>
                                    A képek csak illusztrációk.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Ribbon/>
    </div>
</template>

<script>
import Ribbon from '@/components/Ribbon.vue'

export default {
    components:{
        Ribbon
    },
    methods: {      
        setGtm(btnName){
            this.GTtrackGA4({
                'event' : 'event',
                'category': 'jatekleiras',
                'action': 'gomb_kattintas',
                'label': btnName,                
                'clicked_text':btnName,
                'action_type': 'gomb_kattintas'
            })
        }
    }
}
</script>